jQuery(document).ready(function($) {

    /*
     * Replace all SVG images with inline SVG
     */
    $('img.svg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });       
        
    var color_array = [
        {color:"#5d5d5d", duration:4000},
	{color:"#38d308", duration:4000},
	{color:"#664904", duration:4000},
	{color:"#000000", duration:4000}	
    ];

    var active_slide = 0;

    // set timeout
    var tid = setTimeout(function(){
            mycode(color_array,active_slide);
    }, 0);
    
    function mycode(color_array,active_slide) {
    
            //Ausblenden des letzten Slides, bzw. Starten des Ersten
            if(active_slide == 0){
                    //beim Start
                    active_slide = 1;
                    //$( "#construction_page label" ).css('color',color_array[0].color+' !important');
                    //$( "#construction_page .svg path" ).css('fill',color_array[0].color+' !important');
            } else {
                    //wenns mal läuft
                    if(active_slide < color_array.length){
                            active_slide++;			
                    } else {
                            active_slide = 1;
                    }	
            }            
            
            original_color = color_array[(active_slide-1)].color;
            //$( "#construction_page label" ).animate({ color: original_color }, color_array[(active_slide-1)].duration, null, function () {});
            var logo_label = $( "#construction_page label" );
            logo_label.css({color: original_color, transition: color_array[(active_slide-1)].duration+"ms"});

            var svg = $( "#construction_page" ).find('svg path');
            svg.css({fill: original_color, transition: color_array[(active_slide-1)].duration+"ms"});          

            tid = setTimeout(function(){
                    mycode(color_array,active_slide);
            }, color_array[(active_slide-1)].duration); // repeat myself			  
    }
    function abortTimer() { // to be called when you want to stop the timer
      clearTimeout(tid);
    }
        
});