/**
 * Get the host of the location
 *
 * e.g mirabit.com:8082, google.ch:92
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getHost() {
    return jQuery(location).attr('host');
}

/**
 * Get the hostname of the location
 *
 * e.g mirabit.com, google.ch
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getHostname() {
    return jQuery(location).attr('hostname');
}

/**
 * Get the port of the location
 *
 * e.g 80, 8080, 443
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getPort() {
    return jQuery(location).attr('port');
}

/**
 * Get the protocol of the location
 *
 * e.g http:, https:
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getProtocol() {
    return jQuery(location).attr('protocol');
}

/**
 * Get the pathname of the location
 *
 * e.g index.php, de/foo/bar
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getPathname() {
    return jQuery(location).attr('pathname');
}

/**
 * Get the full href
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getHref() {
    return jQuery(location).attr('href');
}

/**
 * Get the hash of the location
 *
 * e.g #foo, #bar
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getHash() {
    return jQuery(location).attr('hash');
}

/**
 * Get the GET Parameter
 *
 * e.g ?foo=123, ?foo=123&bar=456
 *
 * @returns {*|string|JQuery|jQuery}
 */
function getSearch() {
    return jQuery(location).attr('search');
}